@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

$body-bg: #fafafa;

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$body-color: #333;

$primary: #1ba683;
$secondary: #0e8c6c;
$light: #ddd;
$positive: #34bc82;
$neutral: #ffdd50;
$negative: #f96969;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light": $light,
    "positive": $positive,
    "neutral": $neutral,
    "negative": $negative,
);
