// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

#root {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

body {
    @include media-breakpoint-up(md) {
        background: url("/static/auth_background.jpg") center center no-repeat fixed;
        background-size: cover;
    }
}

.e-auth {
    position: unset;

    @include media-breakpoint-up(md) {
        width: 27rem !important;
        background: #fff;
        border-radius: 5px;
        box-shadow: 3px 3px 30px 5px rgba(204, 204, 204, 0.9);
        position: relative;

        @at-root #{&}--wide {
            width: 35rem !important;
        }
    }

    @at-root #{&}__message {
        color: #504c4b;
        font-weight: 400;
    }

    @at-root #{&}__input-group {
        position: relative;

        i {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            color: #aaa;
            padding: .9rem 1rem;
            font-size: 1.6rem;
        }

        input {
            background-color: #efefef;
            color: #666;
            padding: .5rem .5rem .5rem 2.5rem;
            font-size: 1.5rem;
        }
    }

    @at-root #{&}__link {
        font-weight: 400;
        color: $primary ;
        text-decoration: none;
    }

    @at-root #{&}__close {
        font-size: 1.2rem;
        position: absolute;
        top: -.1rem;
        right: 0;
        padding: .5rem;

        i {
            color: $primary;
            cursor: pointer;
        }
    }
}
